import styled from '@emotion/styled'
import { lighten } from 'polished'

import { Link } from 'gatsby'

import { breakpoints } from './styles'
import { skyBlue, skyBlue10, oceanBlue, copper } from '../constants/theme';

import CopperWire from '../images/copper-wires.jpg';

export const BasicPageContain = styled.div`
  margin-top: 30px;
`;

export const FormContain = styled.div`

  font-family: 'HKNova-Medium';

  label, input, textarea {
    box-sizing: border-box;
    display: block;
    width: 240px;
    margin-bottom: 4px;
  }

  input.radio-input {
    display: inline-block;
    width: 20px;
  }

  label.radio {
    display: inline;
  }

  input, textarea {
    font-family: 'HKNova-Medium';
    padding: 3px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .btn {
    background-color: ${copper};
    color: white;
    border: 1px solid ${copper};
    border-radius: 0;
    padding: 10px;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: ${copper};
    }
  }
`;


export const ButtonStyle = styled(Link)`
  display: inline-block;
  box-sizing: border-box;
  margin-top: 10px;
  text-align: center;
  background-color: ${skyBlue};
  font-size: 20px;
  padding: 8px 20px;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
  color: white;
  text-decoration: none;
  transition: background-color 200ms;

    /* text-decoration: none;
    color: ${skyBlue};
    font-size: 14px;
    font-family: 'HKNova-Bold';
    letter-spacing: 0.10rem;
    line-height: 1.4; */

  

  &:focus {
    outline: none;
  }

  &.adding {
    background-color: ${lighten(0.05, copper)};
    color: white;
  }

  &:hover {
    background-color: ${lighten(0.05, copper)};
    transition: background-color 200ms;
  }
`;



export const ImitationsBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  .grid {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.m}px){
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${breakpoints.s}px){
      grid-template-columns: repeat(1, 1fr);
    }
  }

  p {
    font-size: 14px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    line-height: 1.4;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
    border-radius: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
  }
`;

export const Grid = styled.div`
  display: flex;
  max-width: 1320px;
  width: 100%;
  margin-top: 60px;

  @media (max-width: ${breakpoints.m}px){
    flex-direction: column;
  }
`;

export const GridLeft = styled.div`
  width: 600px;
  @media (max-width: ${breakpoints.s}px){
    width: 100%;
  }
`
export const GridRight = styled.div`
  width: 100%;
  @media (max-width: ${breakpoints.m}px){
    margin-top: 30px;
  }
`

export const FaqSection = styled.div`
  font-family: 'HKNova-Regular';

  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 40px;
  }

  strong {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: 'HKNova-Medium';
    display: block;
  }

  p {
    max-width: 90%;
    font-size: 16px;
    letter-spacing: 0;
  }
`;

export const ImageBlockStyle = styled.div`

  background-image: url('${CopperWire}');
  padding: 30px;
  transform: translateY(-30px);

  .caption {
    padding: 30px;
    max-width: 300px;
    color: white;
    background-color: ${oceanBlue};

    p {
      font-size: 14px;
      font-family: 'HKNova-Medium';
      span {
        color: ${copper};
      }
    }

    h3 {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 21px;
      margin-left: 3px;
    }

    h2 {
      font-family: 'HKNova-Regular';
      letter-spacing: 0.10rem;
      font-weight: normal;
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 46px;
      font-family: 'gilroyExtraBold';
      letter-spacing: 0.1rem;
      font-weight: normal;
      margin-top: 0;
      line-height: 1.12;
      margin-bottom: 10px;
      color: ${copper};
    }
  }
`;

export const StudyContain = styled.div`

  .study-contain-item {
    background-color: ${skyBlue10};
    padding: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
      margin-bottom: 15px;
      font-size: 32px;
    }

    p.question {
      font-weight: bold;
      font-size: 12px;
      width: 100%;
      max-width: 100%;
      color: ${skyBlue};
    }

    p {
      font-size: 14px;
      max-width: 100%;
      width: 100%;
    }

    h3 {
      font-size: 10px;
    }

    a {
      color: ${skyBlue};
      margin-top: 30px;
      text-decoration: none;
      font-size: 13px;
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      line-height: 1.4;
    }
  }
`;


export const CopyBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  margin-bottom: 40px;


  &.sale-page {
    text-align: center;
  }

  &.text-center {
    text-align: center;
  }

  .grid {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.s}px){
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  p {
    font-size: 13px;
    font-family: 'HKNova-Regular';
    max-width: 320px;
    line-height: 1.4;
  }


  &.text-center p {
    max-width: 420px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  p.sales-text {
    font-size: 18px;
    max-width: 420px;
    margin: auto;
    margin-bottom: 40px;
  }

  .mt40 {
    margin-top: 40px;
  }

  a.mt40 {
    display: block;
  }

  p.wide {
    max-width: 600px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 3px;
    line-height: 2;

    span {
      font-size: 18px;
    }
  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
    border-radius: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;

    @media (max-width: ${breakpoints.s}px){
      font-size: 32px;
    }


  }

  a {
    text-decoration: none;
    color: ${skyBlue};
    font-size: 14px;
    font-family: 'HKNova-Bold';
    letter-spacing: 0.10rem;
    line-height: 1.4;
  }
`;
