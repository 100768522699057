import React from 'react';
import SEO from '../components/seo';

import { BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight, StudyContain, ImageBlockStyle, ImitationsBlockStyle } from '../utils/copperTypography';

import Icon1 from '../images/icons/copper-icon-copper-content.png';
import Icon2 from '../images/icons/copper-icon-compression.png';
import Icon3 from '../images/icons/copper-icon-anti-microbial.png';
import Icon4 from '../images/icons/copper-icon-moisture-wicking.png';
import Icon5 from '../images/icons/copper-icon-flexible-fabric.png';
import Icon6 from '../images/icons/copper-icon-anti-odor.png';

const ImageBlock = () => (
  <ImageBlockStyle>
    <div className='caption'>

      <img width="75" src={Icon1} alt='banner' />
      <h2>
      Copper Compression products have the highest copper content,
      </h2>
      <h1>
        Guaranteed.<br />
      </h1>

      <p>
      Instead of spraying fabrics with
      copper, we infuse it directly into the
      nylon fibers. Our products are constructed with <span>85% copper-infused nylon.</span>
      This innovative approach provides maximum benefits, extending the life of the product for a superior experience.
      </p>

    </div>
  </ImageBlockStyle>
)

const BenefitsBlock = () => (
  <ImitationsBlockStyle>
      <div className='grid-contain'>
        <div className='grid'>
          <div className='block'>
            <img width="75" src={Icon1} alt='banner' />
            <h2>Copper Infused</h2>
            <p>Copper is woven into our fabric not sprayed so it doesn’t just wash away.</p>
          </div>
          <div className='block'>
            <img width="75" src={Icon2} alt='banner' />
            <h2>Compression</h2>
            <p>Adds stability & support to your body part when engaging in various activities.</p>
          </div>
          <div className='block'>
            <img width="75" src={Icon3} alt='banner' />
            <h2>Antimicrobial</h2>
            <p>Antimicrobial properties provide maximum benefits, extending the life of the product for a superior experience</p>
          </div>
          <div className='block'>
            <img width="75" src={Icon4} alt='banner' />
            <h2>Moisture Wicking</h2>
            <p>Material wicks sweat & dries really fast. Adds stability to your body part when engaging in various activities</p>
          </div>
          <div className='block'>
            <img width="75" src={Icon5} alt='banner' />
            <h2>Flexible Fabric</h2>
            <p>4-way stretch construction moves better in every direction</p>
          </div>
          <div className='block'>
            <img width="75" src={Icon6} alt='banner' />
            <h2>Anti Odor</h2>
            <p>Our copper infused fabric helps keep bad odors away</p>
          </div>
        </div>
      </div>
  </ImitationsBlockStyle>
);


const BenefitsPage = () => (
  <BasicPageContain>
    <SEO title="Benefits" />
    <ImageBlock />
    <CopyBlockStyle>
      <h3>Benefits</h3>
      <h1>Strength to Heal</h1>
      <h2>Feel the difference</h2>
      <p className='wide'>Functionality is one of the defining
principles behind every Copper Compression product. Our diverse offering not only provides support and protection, but
allows you to move on with life.</p>
      <BenefitsBlock />
    </CopyBlockStyle>


    <CopyBlockStyle>
      <Grid>
        <GridLeft>
          <h3>Benefits</h3>
          <h1>Four Studies</h1>
          <p>Related studies from The National Institute of Health</p>
        </GridLeft>

        <GridRight>
          <StudyContain>
            <div className="study-contain-item">
              <h3>National Institute of Health</h3>
              <h1>Are compression garments effective for the recovery of exercise-induced muscle damage?</h1>
              <p>“… Analyses of power… and strength… indicate faster recovery of muscle function after exercise.”</p>
              <a href="http://www.ncbi.nlm.nih.gov/pubmed/26522739" target="_blank" rel="noopener noreferrer">Read more about the study</a>
            </div>      

            <div className="study-contain-item">
              <h3>National Institute of Health</h3>
              <h1>Compression recovery effects on exercise-induced muscle damage in young, active females.</h1>
              <p>“… The results indicate that compression clothing is an effective recovery strategy following exercise-induced muscle damage.”</p>
              <a href="http://www.ncbi.nlm.nih.gov/pubmed/20376479" target="_blank" rel="noopener noreferrer">Read more about the study</a>
            </div>

            <div className="study-contain-item">
              <h3>National Institute of Health</h3>
              <h1>Effects of compression clothing on performance and recovery</h1>
              <p>“When compression clothing was applied for recovery purposes after exercise, small to moderate effect sizes were observed in recovery of maximal strength and power, and reductions in muscle swelling and perceived muscle pain; blood lactate removal; and increases in body temperature.”</p>
              <a href="http://www.ncbi.nlm.nih.gov/pubmed/23302134" target="_blank" rel="noopener noreferrer">Read more about the study</a>
            </div>

            <div className="study-contain-item">
              <h3>National Institute of Health</h3>
              <h1>Metallic Copper as an Antimicrobial Surface</h1>
              <p>“Bacteria, yeasts, and viruses are rapidly killed on metallic copper surfaces, and the term “contact killing” has been coined for this process.”</p>        
              <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC3067274/" target="_blank" rel="noopener noreferrer">Read more about the study</a>
            </div>
          </StudyContain>
        </GridRight>
      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
);

export default BenefitsPage;
